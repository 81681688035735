import React, { useContext, useEffect, useState } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { Draggable } from 'react-beautiful-dnd';
import '../../../../../Style/theme-block-section.css'
import { PageData } from '../../../../../ContextAPI/Context';
import Block from '../Block';
import { ThreeSectionLayout } from '../../../../../Constant';

const DroppableBlocks = ({ parentWidgetId, isParentVisible, widgets, onClick, isOpen, parentWidgetType }) => {

    const { requiredData } = useContext(PageData);
    const [isDragDisabled, setIsDragDisabled] = useState([]);

    useEffect(() => {
        setIsDragDisabled(requiredData.stopChanges);
    }, [requiredData.stopChanges]);

    return (
        <Droppable droppableId={`${widgets.id}`}>
            {(provided) => (
                <div
                    ref={provided.innerRef}
                    id={`${widgets.id}`}
                    className={`droppable_zone_${parentWidgetId}`}
                >
                    {widgets.blocks?.map((block, index) => {
                        return (
                            <Draggable key={block.id} draggableId={`${block.id}`} index={index} isDragDisabled={isDragDisabled} >
                                {(provided) => (
                                    <div ref={provided.innerRef} {...provided.draggableProps} key={index}>
                                        <Block
                                            block={block}
                                            widgets={widgets}
                                            index={index}
                                            onClick={onClick}
                                            isOpen={isOpen}
                                            parentWidgetId={parentWidgetId}
                                            isParentVisible={isParentVisible}
                                            provided={provided}
                                            parentWidgetType={parentWidgetType}
                                        />
                                    </div>
                                )}
                            </Draggable>
                        )
                    })}
                    {widgets.blocks?.length === 0 && ThreeSectionLayout.includes(widgets.type) && (
                        <div className="no-blocks-added">
                            Drag here
                        </div>
                    )}
                    {provided.placeholder}
                </div>
            )}
        </Droppable>
    )
}
export default DroppableBlocks;