import React, { useContext, useEffect, useMemo, useState } from 'react';
import { isArray } from 'lodash';
import { t } from 'i18next';
import { CreatedNewObj, canUpdateField, changeLayout, checkTargetConditions, updateSyncSettings, getConditionalOption, getTargetedValue, syncComponentSettings, updateTargetedValue } from '../../../../../../Utils/Common';
import { PageData } from '../../../../../../ContextAPI/Context';
import { ConditionalKeys, ComponentType, ProductDisplayTypes, UpsellBlocks, ThirdPartyApps, WidgetType, WidgetCategoryType, ColumnLayout, DiscountType } from '../../../../../../Constant';
import ProductSelector from '../../ProductUpsell/Components/ProductSelector';
import { Slider, FieldGroup, RadioButtonGroup, NumberInput, Label, Text, Button, TextInput, Dropdown, TagInput, CheckBox, TextEditor, LayoutSelector, MultiSelectDropdown } from '../../../../../../Shared/Components';
import { getInstallationStatus } from '../../../../../../Service';
import ThirdPartyAppModal from '../../ProductUpsell/Components/ThirdPartyAppModal';
import GroupContainer from '../GroupContainer';
import HelpText from '../../../../../../Shared/Components/HelpText';

const RenderComponent = (props) => {
  const { latestConfigSetting, updateData, setSettingType, setIsAppInstalled, isAppInstalled, isAppIntegrated, setIsAppIntegrated, appInstallationUrl,
    setAppInstallationUrl, setIsCheckStatus, setIsCheckStatusDescription, systemSetting, selectedWidgetType, setSelectedSection, setUnifiedEditor, unifiedEditor, setIsDynamicSync, isMobileView } = useContext(PageData);
  const { setting, id, widgetId, parentWidgetId, target, componentType, collections, max, syncSettings, index } = props.data;
  const [tags, setTags] = useState([]);
  
  const [isModalactive, setisModalActive] = useState(false);
  const [modalTitle, setmodalTitle] = useState("");
  const [ismodalTitleShow, setismodalTitleShow] = useState("");
  const [selectedProductType, setselectedProductType] = useState("");
  const [selectedProduct, setselectedProduct] = useState("");
  const [modalLogo, setmodalLogo] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedPreviousProduct, setSelectedPreviousProduct] = useState("");

  useEffect(() => {
    const targetedValue = getTargetedValue(latestConfigSetting, `${target}.${setting.target}`, widgetId, parentWidgetId, componentType, id, index);
    const tags = targetedValue?.length > 0 ? targetedValue : [];
    setTags(tags);
    if(latestConfigSetting?.destinationWidgetId) {
      delete latestConfigSetting.destinationWidgetId;
      updateData(latestConfigSetting);
    }
  }, [latestConfigSetting, target, widgetId, parentWidgetId, componentType]);
  
  useEffect(() => {
    const targetedValue = getTargetedValue(latestConfigSetting, `${target}.${setting.target}`, widgetId, parentWidgetId, componentType, id, index);
    if(ProductDisplayTypes.includes(targetedValue)) {
      setselectedProduct(targetedValue);
    }
  }, []);

  useEffect(() => {
    // targeted value is true the sync update sync settings value
    const value = getTargetedValue(latestConfigSetting, `${target}.${setting.target}`, widgetId, parentWidgetId, componentType, id);
    if (value && setting?.isDynamicSyncSettings) {
      const updatedEditorSchema = updateSyncSettings(unifiedEditor, latestConfigSetting, widgetId, parentWidgetId, componentType, value);
      setUnifiedEditor(updatedEditorSchema);
      setIsDynamicSync(true); 
    }
  }, []);

  const handleChange = (e, targetedPath, dependedTarget, fieldsOrOptions, type, parentTarget, isDynamicSyncSettings, label = "") => {
    let value = e;
    let isSyncSettings = syncSettings;
    const cloneLatestConfigSettings = CreatedNewObj(latestConfigSetting);
    let updatedSettings = updateTargetedValue(cloneLatestConfigSettings, targetedPath, value, widgetId, parentWidgetId, componentType, id, index, unifiedEditor);
    //Sync all settings for the same component if isDynamicSyncSettings is true in json schema for multiProduct widgets
    if (isDynamicSyncSettings) {
      if (value) {
        isSyncSettings = value;
        // get all data of current component and sync data for same component inside multiProduct widgets
        const componentData = getTargetedValue(cloneLatestConfigSettings, target, widgetId, parentWidgetId, componentType, id);
        updatedSettings = syncComponentSettings(updatedSettings, target, componentData, parentWidgetId, widgetId, componentType, isSyncSettings);
      } else {
        setIsDynamicSync(false);
      }
      const updatedEditorSchema = updateSyncSettings(unifiedEditor, cloneLatestConfigSettings, widgetId, parentWidgetId, componentType, value);
      setUnifiedEditor(updatedEditorSchema);
    }
    
    if(dependedTarget) {
      if (label === "do_not_display_compare_to_price") {
        // added belows condition because from editor side the label is nagetive type value but the key we are passing on save has type of positive side and because of that the functionlity was not working properly for don't show the compare to price 
        updatedSettings = updateTargetedValue(cloneLatestConfigSettings, dependedTarget, !value, widgetId, parentWidgetId, componentType, id, index, unifiedEditor);
        updatedSettings = syncComponentSettings(updatedSettings, dependedTarget, !value, parentWidgetId, widgetId, componentType, isSyncSettings);
      } else {
        if (selectedWidgetType === WidgetType.Banner && type && type === ComponentType.Checkbox) {
          // If banner with 'display button' is selected, then update the category value is 'CLICKABLE' otherwise 'INFORMATIONAL'
          let category = WidgetCategoryType.informational;
          if(value) {
            category = WidgetCategoryType.clickable;
          }
          updatedSettings = updateTargetedValue(updatedSettings, dependedTarget, category, widgetId, parentWidgetId, componentType, id, index);
          
          // If isSyncSettings is true, then update the target value with the value of the sync block.
          if(isSyncSettings) {
            updatedSettings = syncComponentSettings(updatedSettings, dependedTarget, category, parentWidgetId, widgetId, componentType, isSyncSettings);
          } 
        } 
  
        // when dropdown is change for timer then update the dependent target value
        if (type && type === ComponentType.Dropdown && fieldsOrOptions[0]?.dependedValue) {
          let dependedValue = fieldsOrOptions[0].dependedValue;
          if (value === fieldsOrOptions[0].value) {
            dependedValue = fieldsOrOptions[0].dependedValue
          } else {
            dependedValue = fieldsOrOptions[1].dependedValue
          }
          updatedSettings = updateTargetedValue(updatedSettings, dependedTarget, dependedValue, widgetId, parentWidgetId, componentType, id);
  
          // If isSyncSettings is true, then update the target value with the value of the sync block.
          if (isSyncSettings) {
            updatedSettings = syncComponentSettings(updatedSettings, dependedTarget, dependedValue, parentWidgetId, widgetId, componentType, isSyncSettings);
          }
        }
      }
    }

    // If isSyncSettings is true, then update the target value with the value of the sync block.
    if(isSyncSettings) {
      updatedSettings = syncComponentSettings(updatedSettings, targetedPath, value, parentWidgetId, widgetId, componentType, isSyncSettings);
    }

    if (type && type === ComponentType.FieldGroup) {
      /*
       If any value changed for FieldGroup then update the value(dependentValue) in dependent target.
       Note: DependentValue is static value set in editor schema.
      */
      if (isArray(fieldsOrOptions)) {
        updatedSettings =  updateTargetedValue(updatedSettings, `${parentTarget}.${dependedTarget}`, fieldsOrOptions[0].dependedValue, widgetId, parentWidgetId);
      }

      // If isSyncSettings is true, then update the target value with the value of the sync block.
      if(isSyncSettings) {
        updatedSettings = syncComponentSettings(updatedSettings, `${parentTarget}.${dependedTarget}`, fieldsOrOptions[0].dependedValue, parentWidgetId, widgetId, componentType, isSyncSettings);
      }
    }

    if (ProductDisplayTypes.includes(value)) {
      setSettingType(value);
    }
    // if MAIN_PRODUCT option is selected
    // set image key value to empty string
    if (value === ConditionalKeys.MainProduct) {
      const imageTarget = targetedPath.slice(0, targetedPath.lastIndexOf('.') + 1) + 'imageURL';
      updatedSettings = updateTargetedValue(updatedSettings, imageTarget, '', widgetId, parentWidgetId, componentType, id, index);
      
      // If isSyncSettings is true, then update the target value with the value of the sync block.
      if(isSyncSettings) {
        updatedSettings = syncComponentSettings(updatedSettings, imageTarget, '', parentWidgetId, widgetId, componentType, isSyncSettings);
      }
    }

    
    if (updatedSettings) {
      updateData(updatedSettings);
    }

    if(Object.keys(ThirdPartyApps).includes(e)) {
      setselectedProduct((prev) => {
        setSelectedPreviousProduct(prev);
        return value;
      })

      setisModalActive(true);

      const appData = ThirdPartyApps[e];
      setmodalLogo(appData.modalLogo);
      setmodalTitle(appData.modalTitle);
      setismodalTitleShow(appData.showModalTitle);
      setselectedProductType(appData.productType);
    } else if (ProductDisplayTypes.includes(e)) {
      setselectedProduct((prev) => {
        setSelectedPreviousProduct(prev);
        return value;
      })
    }
  };

  const isModalShow = async (e) => {
    setisModalActive(e.isActive);

    if(e.isInstalled === false){
      // When any error message display on selection of wise/presonalised/recomatic, then user closes the model then selected product set as previous selected value  
      // const dependentTarget =`${target}.sectionSettings.product.productToDisplay`;
      const cloneSettings = await CreatedNewObj(latestConfigSetting);
      const updatedSettings = await updateTargetedValue(cloneSettings, `${target}.${setting.target}`, selectedPreviousProduct, widgetId, parentWidgetId, componentType, id, index);
      updateData(updatedSettings)
      // If selected product through any error then user close the model set setSelectedProduct as selectedPreviousProduct
      setselectedProduct(selectedPreviousProduct); 
      setSelectedPreviousProduct(selectedPreviousProduct);
    }
  }

  const handleTagsChange = (value) => {
    if (value.trim() !== '') {
      const nextSelectedTags = new Set([...tags]);

      if (nextSelectedTags.has(value)) {
        nextSelectedTags.delete(value);
      } else {
        nextSelectedTags.add(value);
      }

      setTags([...nextSelectedTags]);

      const tagString = [...nextSelectedTags];
      const cloneSettings = CreatedNewObj(latestConfigSetting);
      let updatedSettings = updateTargetedValue(cloneSettings, `${target}.${setting.target}`, tagString, widgetId, parentWidgetId, componentType, id, index);
      
      // If syncSettings is true, then update the target value with the value of the sync block.
      if(syncSettings) {
        updatedSettings = syncComponentSettings(updatedSettings, `${target}.${setting.target}`, tagString, parentWidgetId, widgetId, componentType, syncSettings);
      }

      updateData(updatedSettings);
    }
  };

  const handleClick = (e, target) => {
    let value = true;
    const cloneSettings = CreatedNewObj(latestConfigSetting);
    let updatedSettings = updateTargetedValue(cloneSettings, target, value, widgetId, parentWidgetId);
    // If syncSettings is true, then update the target value with the value of the syncked component.
    if(syncSettings) {
      updatedSettings = syncComponentSettings(updatedSettings, target, value, parentWidgetId, widgetId, componentType, syncSettings);
    }
    updateData(updatedSettings);
  }

  const handleRangeSliderChange = (value) => {
    const cloneSettings = CreatedNewObj(latestConfigSetting);
    let updatedSettings = updateTargetedValue(cloneSettings, `${target}.${setting.target}`, value, widgetId, parentWidgetId, componentType, id);

     // If syncSettings is true, then update the target value with the value of the sync block.
     if(syncSettings) {
      updatedSettings = syncComponentSettings(updatedSettings, `${target}.${setting.target}`, value, parentWidgetId, widgetId, componentType, syncSettings);
    }

    updateData(updatedSettings);
  }

  useEffect(() => {
    if (selectedProductType) {
      onCheckStatus()
    }
  }, [selectedProductType]);

  const onCheckStatus = async () => {
    setLoading(true);
    if (selectedProductType) {
      try {
        const res = await getInstallationStatus(selectedProductType)
        const { data } = res;
        const { installed, url, integrated } = data;
        setIsAppInstalled(installed);
        setAppInstallationUrl(url);
        setIsAppIntegrated(integrated);
        if (installed && integrated) {
          setIsCheckStatus(false);
          setIsCheckStatusDescription(false);
        }
        setLoading(false);
      } catch (error) {
        if(selectedProductType === ThirdPartyApps.PERSONALIZED_RECOMMENDATIONS.productType) {
          setAppInstallationUrl("https://apps.shopify.com/recommendation-kit");
        } else {
          setAppInstallationUrl("");
        }
        console.error(error);
        setIsAppInstalled(false);
        setLoading(false);
      }
    }
  };

  const targetChange = useMemo(() => {
    return {
      minQuantityEnabled: `${target}.minQuantity.enabled`,
      minQuantity: `${target}.minQuantity.value`,
      defaultQuantity: `${target}.defaultQuantity`,
      maxQuantityEnabled: `${target}.maxQuantity.enabled`,
      maxQuantity: `${target}.maxQuantity.value`,
      subTargetDefaultQuantity: 'defaultQuantity',
      subTargetMaxQuantity: 'maxQuantity.value',
      subTargetMinQuantity: 'minQuantity.value',
    };
  }, [target])

  const getMinQuantity = (subTarget, min) => {
    if (componentType === UpsellBlocks.PriceAndDiscount) {
      return 0;
    }
    // Request come for min quantity number field or default number field so return min quantity of default value
    else if (subTarget === targetChange.subTargetDefaultQuantity) {
      const isMinEnable = getTargetedValue(latestConfigSetting, targetChange.minQuantityEnabled, widgetId, parentWidgetId, componentType, id, index);
      if (isMinEnable) {
        // Return minimum quantity
        return getTargetedValue(latestConfigSetting, targetChange.minQuantity, widgetId, parentWidgetId, componentType, id, index);
      }
    }
    // Request come for max quantity number field to get min quantity value.
    else if (subTarget === targetChange.subTargetMaxQuantity) {
      // minimum quantity for max quantity number should be default quantity value
      return getTargetedValue(latestConfigSetting, targetChange.defaultQuantity, widgetId, parentWidgetId, componentType, id, index);
    }
    return min ?? 1;
  }

  const getMaxQuantity = (subTarget, dependedTarget, max) => {
    if (componentType === UpsellBlocks.PriceAndDiscount) {
      return [DiscountType.IncrementalQuantityBreaks, DiscountType.Percentage].includes(getTargetedValue(latestConfigSetting, dependedTarget, widgetId, parentWidgetId, componentType, id, index)) ? 100 : Infinity
    }
    // Request come for max quantity number field or default number field so return max quantity of default value
    if (subTarget === targetChange.subTargetDefaultQuantity) {
      const isMaxEnable = getTargetedValue(latestConfigSetting, targetChange.maxQuantityEnabled, widgetId, parentWidgetId, componentType, id, index);
      if (isMaxEnable) {
        return getTargetedValue(latestConfigSetting, targetChange.maxQuantity, widgetId, parentWidgetId, componentType, id, index);
      }
    }
    // Request come for min quantity number field then return default quantity value
    else if (subTarget === targetChange.subTargetMinQuantity) {
      return getTargetedValue(latestConfigSetting, targetChange.defaultQuantity, widgetId, parentWidgetId, componentType, id, index);
    }

    return max ?? Infinity;
  }

  const handleLayoutChange = (value) => {
    let targetedWidget = getTargetedValue(latestConfigSetting, target, widgetId, parentWidgetId);
    if (ColumnLayout.includes(value)) {
      // When layout is changed ONE_COLUMN, TWO_COLUMN or THREE_COLUMN column then added internal widgets like : "Left", "Right", "Bottom" and separate blocks for 2 and 3 column layouts using location 1, 2, 3
      targetedWidget = changeLayout(targetedWidget, value, targetedWidget?.upsellInfo?.layout);
    }

    const updatedSettings = updateTargetedValue(latestConfigSetting, `${target}.${setting.target}`, value, widgetId, parentWidgetId, componentType, id);

    setSelectedSection({ id: targetedWidget?.id, isOpen: true });
    updateData(updatedSettings);
  };

  const getSuffixValue = (subTarget, showSuffix) => {
    if (componentType === UpsellBlocks.PriceAndDiscount && showSuffix) {
      return [DiscountType.IncrementalQuantityBreaks, DiscountType.Percentage].includes(getTargetedValue(latestConfigSetting, subTarget, widgetId, parentWidgetId, componentType, id, index)) ? '%' : systemSetting?.currencySymbol ? systemSetting.currencySymbol : '$';
    }
  }

  if (setting.conditional && !checkTargetConditions({widgets: latestConfigSetting, target: `${target}.${setting.target}`, conditions: setting.condition, widgetId: widgetId, parentWidgetId: parentWidgetId, _type: componentType, id: id, targetedindex: index, isMobileView})) {
    return null;
  } else {
    const conditionalOption = setting?.conditionalOption ? getConditionalOption(setting, latestConfigSetting, id, widgetId, parentWidgetId) : setting.options === 'fetchOptions' ? collections : setting.options;
    return (
      <>
        {setting.type === ComponentType.Checkbox && (
          <CheckBox
            label={setting.label}
            checked={getTargetedValue(latestConfigSetting, `${target}.${setting.target}`, widgetId, parentWidgetId, componentType, id)}
            onChange={e => handleChange(e, `${target}.${setting.target}`, `${target}.${setting.dependedTarget}`, '', setting.type, '', setting?.isDynamicSyncSettings, setting.label)}
            showTooltip={setting.showTooltip}
            tooltipText={setting.tooltipText}
            helpText={setting.helpText}
            disabled={!setting.updateConditions || canUpdateField(latestConfigSetting, `${target}`, setting.updateConditions, widgetId, parentWidgetId, componentType, id) ? false : true}
          />
        )}

        {setting.type === ComponentType.TextField && (
          <TextInput
            label={setting.label}
            value={getTargetedValue(latestConfigSetting, `${target}.${setting.target}`, widgetId, parentWidgetId, componentType, id)}
            onChange={e => handleChange(e, `${target}.${setting.target}`)}
            showTooltip={setting.showTooltip}
            tooltipText={setting.tooltipText}
            multiline={setting.isMultiline}
            currentWidgets={{selectedWidgetType,latestConfigWidgets:latestConfigSetting.widgets,currentId:parentWidgetId,productUpsellWarningMessages:{"unique_name":t(setting.uniqueNameWaringMessage),"required":t(setting.requiredWaringMessage)}}}
            maxLimitWaringMessage={t(setting.maxLimitWaringMessage)}
            maxLength={setting.maxLength} 
            placeholder={setting.placeholder}           
          />
        )}

        {setting.type === ComponentType.TextEditor && (
          <TextEditor
            label={setting.label}
            tooltipText={setting.tooltipText}
            defaultText={setting.defaultText}
            showTooltip={setting.showTooltip}
            value={getTargetedValue(latestConfigSetting, `${target}.${setting.target}`, widgetId, parentWidgetId, componentType, id)}
            onChange={(e) => handleChange(e, `${target}.${setting.target}`)}
          />
        )}

        {setting.type === ComponentType.Dropdown && (
          <div>
            <Dropdown
              showTooltip={setting.showTooltip}
              tooltipText={setting.tooltipText}
              label={setting.label}
              options={conditionalOption}
              onChange={e => handleChange(e, `${target}.${setting.target}`, `${target}.${setting.dependedTarget}`, setting.options , setting.type)}
              value={getTargetedValue(latestConfigSetting, `${target}.${setting.target}`, widgetId, parentWidgetId, componentType, id, index)}
              helpText={setting?.helpText && setting.helpText}
            />
          </div>
        )}

        {setting.type === ComponentType.MultiSelectDropDown && (
          <div>
            <MultiSelectDropdown
              showTooltip={setting.showTooltip}
              tooltipText={setting.tooltipText}
              label={setting.label}
              options={conditionalOption}
              onChange={e => handleChange(e, `${target}.${setting.target}`, `${target}.${setting.dependedTarget}`, setting.options, setting.type)}
              value={getTargetedValue(latestConfigSetting, `${target}.${setting.target}`, widgetId, parentWidgetId, componentType, id, index)}
              helpText={setting?.helpText && setting.helpText}
              excludeOptions={setting?.excludeOptions}
            />
          </div>
        )}

        {setting.type === ComponentType.TagEditor && (
          <TagInput
            onChange={handleTagsChange}
            tags={tags}
            label={setting.label}
            placeholder={t(setting.placeholder)}
            tooltipText={t(setting.tooltipText)}
            showTooltip={setting.showTooltip}
          />
        )}

        {setting.type === ComponentType.Button && (
          <Button onClick={(e) => handleClick(e, `${target}.${setting.target}`)} description={setting.description} bannerStatus={setting.bannerStatus} bannerText={setting.bannerText}>
            {t(setting.label)}
          </Button>
        )}


        {setting.type === ComponentType.Selector && (<ProductSelector data={{ ...props.data, target: `${target}.${setting.target}`, index:index }} />)}

        {setting.type === ComponentType.Slider && (
          <>
            <Slider
              label={t(setting.label)}
              value={getTargetedValue(latestConfigSetting, `${target}.${setting.target}`, widgetId, parentWidgetId, componentType, id)}
              onChange={handleRangeSliderChange}
              suffix={getTargetedValue(latestConfigSetting, `${target}.${setting.target}`, widgetId, parentWidgetId, componentType, id)}
              step={setting.step}
              min={setting.minVal}
              max={setting.maxVal || max}
              output
            />
          </>
        )}

        {setting.type === ComponentType.FieldGroup && (
          <FieldGroup
            label={t(setting.label)}
            fields={setting.fields}
            fieldsType={setting.fieldsType}
            onChange={e => handleChange(e, `${target}.${setting.target}`, setting.dependedTarget, setting.fields, setting.type, target)}
            value={getTargetedValue(latestConfigSetting, `${target}.${setting.target}`, widgetId, parentWidgetId, componentType, id)}
            showTooltip={setting.showTooltip}
            tooltipText={setting.tooltipText}
          />
        )}

        {setting.type === ComponentType.RadioButton && (
          <RadioButtonGroup
            label={t(setting.label)}
            options={setting.options}
            value={getTargetedValue(latestConfigSetting, `${target}.${setting.target}`, widgetId, parentWidgetId, componentType, id)}
            isShowTooltip={setting.showTooltip}
            tooltipContent={setting.tooltipText}
            onChange={e => handleChange(e, `${target}.${setting.target}`)}
          />
        )}

        {setting.type === ComponentType.NumberField && (
          <NumberInput
            label={setting.label}
            suffix={getSuffixValue(`${target}.${setting.suffix}`, setting.suffix)}
            value={getTargetedValue(latestConfigSetting, `${target}.${setting.target}`, widgetId, parentWidgetId, componentType, id, index)}
            min={getMinQuantity(setting.target, setting.min)}
            max={getMaxQuantity(setting.target, `${target}.${setting.dependedTarget}`, setting.max)}
            onChange={e => handleChange(e, `${target}.${setting.target}`)}
            showTooltip={setting.showTooltip}
            tooltipText={setting.tooltipText}
            minLimitWaringMessage={t(setting?.minLimitWaringMessage)}
            maxLimitWaringMessage={t(setting?.maxLimitWaringMessage)}
          />
        )}

        {setting.type === ComponentType.DescriptionText && (
          <>
            <Label label={setting.label} />
            <Text><p style={{ color: '#6d7175', fontWeight: 400, fontSize: '0.4em' }}>{t(setting.description)}</p></Text>
          </>
        )}

        {setting.type === ComponentType.HelpText && (
          <HelpText helpText={setting.helpText}/>
        )}
        
        {setting.type === ComponentType.GroupContainer && (
          <GroupContainer 
            setting={setting} 
            value={getTargetedValue(latestConfigSetting, `${target}.${setting.target}`, widgetId, parentWidgetId, componentType, id)} 
            onChange={e => handleChange(e, `${target}.${setting.target}`)}
            widgetId={widgetId} 
            parentWidgetId={parentWidgetId} 
            componentType={componentType}
            id={id}
            target={target}
          />
        )}
        {
          isModalactive && 
          <ThirdPartyAppModal 
            isModalactive={isModalactive} 
            setisModalActive={e => isModalShow(e)} 
            onCheckStatus={onCheckStatus}  
            modalTitle={modalTitle} 
            modalLogo={modalLogo} 
            ismodalTitleShow={ismodalTitleShow} 
            appInstallationUrl={appInstallationUrl} 
            selectedProduct={selectedProduct} 
            isAppInstalled={isAppInstalled} 
            isAppIntegrated={isAppIntegrated} 
            loading={loading} 
          /> 
        }

        {setting.type === ComponentType.Layout && (
           <LayoutSelector
            showTooltip={setting.showTooltip}
            tooltipText={setting.tooltipText}
            label={setting.label}
            onChange={(e, oldValue) => handleLayoutChange(e, oldValue, setting.target)}
            value={getTargetedValue(latestConfigSetting, `${target}.${setting.target}`, widgetId, parentWidgetId)}
            layouts={setting.options}
          />
        )}

      </>
    )
  }
}

export default RenderComponent;

